<template>
  <div v-if="loaded" style="width: 100%; height: 100%">
    <sim-wrapper style="width: 100%; height: 100%">
      <stemble-formula-hydrate
        :variation="variation"
        :data="labData"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </sim-wrapper>
  </div>
  <stemble-loader v-else />
</template>

<script>
import {loadScriptOnce, loadStylesOnce} from '@/loading/utils/dynamic-loading';
import StembleLoader from '@/loading/components/StembleLoader';
import SimWrapper from '@/tasks/components/simulations/SimWrapper';

export const UPEI = 0;
export const WINDSOR = 1;

export default {
  name: 'LabFormulaOfHydrate',
  components: {SimWrapper, StembleLoader},
  inheritAttrs: false,
  props: {
    variation: {
      type: Number,
      default: UPEI,
      validate: (value) => [UPEI, WINDSOR].indexOf(value) !== -1,
    },
    crucibleMass: {
      type: Number,
      default: 30,
    },
    crucibleWaterMass: {
      type: Number,
      default: 1,
    },
    hydrateMass: {
      type: Number,
      default: 1,
    },
    hydrateWaterMass: {
      type: Number,
      default: 0.2091,
    },
    yieldFactor: {
      type: Number,
      default: 1.0,
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    labData() {
      return JSON.stringify({
        crucibleMass: this.crucibleMass,
        crucibleWaterMass: this.crucibleWaterMass,
        hydrateMass: this.hydrateMass,
        hydrateWaterMass: this.hydrateWaterMass,
        yieldFactor: this.yieldFactor,
      });
    },
  },
  mounted() {
    Promise.all([
      loadScriptOnce('/simulations/formula-of-hydrate/stemble-formula-hydrate.js'),
      loadStylesOnce('/simulations/formula-of-hydrate/styles.css'),
    ]).then(() => {
      this.loaded = true;
    });
  },
};
</script>

<template>
  <lab-task
    ><lab-formula-of-hydrate
      :variation="variation"
      :crucible-mass="crucibleMass"
      :crucible-water-mass="crucibleWaterMass"
      :hydrate-mass="hydrateMass"
      :hydrate-water-mass="hydrateWaterMass"
      :yield-factor="yieldFactor"
  /></lab-task>
</template>

<script>
import Vue from 'vue';
import {default as LabFormulaOfHydrate, UPEI} from '../simulations/LabFormulaOfHydrate';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import LabTask from './labs/LabTask';

const HYDRATE_MOLAR_MASS = 136.14;
const WATER_MOLAR_MASS = 18.02;

export default Vue.extend({
  name: 'UPEIFormulaOfHydrate',
  components: {LabFormulaOfHydrate, LabTask},
  mixins: [DynamicQuestionMixin()],
  data: () => ({
    variation: UPEI,
  }),
  computed: {
    crucibleMass() {
      return this.taskState.getValueBySymbol('crucibleMass').content.toFloat();
    },
    crucibleWaterMass() {
      return this.taskState.getValueBySymbol('crucibleWaterMass').content.toFloat();
    },
    hydrateMass() {
      return this.taskState.getValueBySymbol('hydrateMass').content.toFloat();
    },
    xValue() {
      return this.taskState.getValueBySymbol('xValue').content.toFloat();
    },
    hydrateWaterMass() {
      return this.hydrateMass / (HYDRATE_MOLAR_MASS / this.xValue / WATER_MOLAR_MASS + 1);
    },
    yieldFactor() {
      // UPEI doesn't haven't a yield as this comes from the second part. Just fill it in with 100%.
      return 1.0;
    },
  },
});
</script>
